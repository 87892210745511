import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import theme from "../theme/theme";
import { type } from "../theme/typography";

import Layout from "../components/layout";
import Container from "../components/container";

const colors = {
  backgroundColor: "hsl(200, 20%, 98%)",
  bodyColor: "hsl(231, 38%, 22%)",
  footerColor: "hsl(246, 8%, 48%)"
};

const Error = styled.div`
  margin: ${theme.spacing.large} 0 148px 0;
`;

const Headline = styled.h1`
  ${type("header1")};
  font-size: 128px;
  line-height: 128px;
  color: ${colors.bodyColor};
  ${theme.breakpoint.down("small")`
    font-size: 96px;
    line-height: 96px;
  `};
`;

const Body = styled.p`
  ${type("body")};
  color: ${colors.bodyColor};
  margin-bottom: ${theme.spacing.small};
`;

const Link2 = styled(Link)`
  ${type("bodySmall")};
  color: hsl(10, 80%, 65%);
`;

export default () => (
  <React.Fragment>
    <Helmet>
      <title>Ryan Lucas | 404</title>
      <meta name="description" content="404: Page not found" />
    </Helmet>

    <Layout
      backgroundColor={colors.backgroundColor}
      bodyColor={colors.bodyColor}
      footerColor={colors.footerColor}
      navColor={colors.bodyColor}
    >
      <Container>
        <Error>
          <Headline>404</Headline>
          <Body>The page you're looking for doesn't seem to exist.</Body>
          <Link2 to="/">Back to home</Link2>
        </Error>
      </Container>
    </Layout>
  </React.Fragment>
);
